import React, {useEffect, useState} from 'react';
import {AppProvider, Page, TextField, Button, Spinner} from '@shopify/polaris';
import styled from 'styled-components';
import axios from 'axios';

const Wrapper = styled.div`
  width: ${({width}) => width || 'auto'};
  height: ${({height}) => height || 'auto'};
  display: flex;
  flex-direction: ${({direction}) => direction || 'row'};
  justify-content: ${({justify}) => justify || 'flex-start'};
  align-items: ${({align}) => align || 'flex-start'};
  padding: ${({padding}) => padding || '0px'};
`;


const Auth = (
    {
      setToken,
    },
) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const handler = () => {
    const logIn = async () => {
      try {
        setSpinner(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/logIn`, {}, {
          headers: {
            Authorization: `Bearer ${password}`,
          },
        });
        sessionStorage.setItem('token', res.data.token);
        setToken(res.data.token);
        setSpinner(false);
        setError(null);
      } catch (e) {
        setSpinner(false);
        setError(e.message);
        console.log(e);
      }
    };
    logIn().catch((e) => console.error(e));
  };


  return (
    <AppProvider i18n='en'>
      <Page>
        <Wrapper>
          <TextField
            autoComplete='off'
            type='password'
            label="Пароль"
            placeholder="Введите пароль"
            value={password}
            maxLength={300}
            error={error}
            onChange={(v) => setPassword(v)}
          />
        </Wrapper>
        <Wrapper padding='20px 0px 0px 0px'>
          {spinner ? (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          ) : (
            <Button size="large" onClick={handler}>Sign In</Button>
          )}
        </Wrapper>
      </Page>
    </AppProvider>
  );
};

export default Auth;
