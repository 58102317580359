import {AppProvider, Button, Page, Spinner} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';
import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import Card from './card/Card';
import TextHandler from './textHandler/TextHandler';
import axios from 'axios';
import Voices from './voices/Voices';
import DialogSentences from './dialogSentences/DialogSentences';


const Wrapper = styled.div`
  width: 100%;
  height: 100vh;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Sentence = () => {
  const navigate = useNavigate();
  const [dialogSentences, setDialogSentences] = useState([]);
  const [modalVoices, setModalVoices] = useState(false);
  const [modal, setModal] = useState(false);
  const [voices, setVoices] = useState([]);
  const [sentences, setSentences] = useState([]);
  const [selected, setSelected] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [restart, setRestart] = useState(false);
  const [saver, setSaver] = useState(['init']);
  const [synchronizer, setSynchronizer] = useState(['init']);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem('sentences'))?.length) {
      setSentences([...JSON.parse(localStorage.getItem('sentences'))]);
    }
  }, []);

  const handler = () => {
    const get = async () => {
      try {
        setModalVoices(false);
        setSpinner(true);
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/getLessonId`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        localStorage.setItem('lessonId', res.data.lessonId);
        setRestart(!restart);
        setSpinner(false);
      } catch (e) {
        setSpinner(false);
        console.log(e);
      }
    };
    get().catch((e) => console.error(e));
  };

  const goToLesson = () => {
    navigate('/createLesson');
  };


  return (
    <AppProvider i18n='en'>
      <Page
        title={localStorage.getItem('lessonId')}
        secondaryActions={localStorage.getItem('lessonId') ? [
          {
            content: 'Delete createLesson Id',
            accessibilityLabel: 'Delete createLesson Id',
            onAction: () => {
              localStorage.removeItem('lessonId');
              localStorage.removeItem('sentences');
              setSentences([]);
              setRestart(!restart);
            },
          },
          {
            content: 'Go to createLesson',
            onAction: () => goToLesson(),
          },
        ] : false}
      >
        {localStorage.getItem('lessonId') ? (
          <Fragment>
            <TextHandler
              setSaver={setSaver}
              setSynchronizer={setSynchronizer}
              voices={voices}
              dialogSentences={dialogSentences}
              lessonId={localStorage.getItem('lessonId')}
              setSentences={setSentences}
              selected={selected}
              setSelected={setSelected}
              sentences={sentences}
            />
            <Card
              saver={saver}
              synchronizer={synchronizer}
              setSynchronizer={setSynchronizer}
              setSaver={setSaver}
              voice={selected}
              sentences={sentences}
              setSentences={setSentences}
            />
          </Fragment>
        ) : (
          <Wrapper>
            {spinner ? (
              <Spinner accessibilityLabel="Spinner example" size="large" />
            ) : (
              <Button variant="primary" onClick={() => setModal(true)}>
                getLessonId
              </Button>
            )}
          </Wrapper>
        )}
      </Page>
      <Voices
        setVoices={setVoices}
        voices={voices}
        modal={modalVoices}
        setModal={setModalVoices}
        apply={handler}
      />
      <DialogSentences
        modal={modal}
        setModal={setModal}
        setModalVoices={setModalVoices}
        setDialogSentences={setDialogSentences}
      />
    </AppProvider>
  );
};

export default Sentence;
