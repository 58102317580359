import React from 'react';
import {nanoid} from 'nanoid';
import WordView from '../word/WordView';

export const renderSentence = ({sentence, setOpen}) => {
  const newSentence = sentence.trim().split(' ');
  return newSentence.map((item) => {
    return (
      <WordView
        key={nanoid()}
        sourceLang={item}
        setOpen={setOpen}
        sentence={sentence}
      />
    );
  });
};
