import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Card, TextField, BlockStack, Checkbox} from '@shopify/polaris';
import axios from 'axios';

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  width: 300px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const WrapperText = styled.div`
  width: 100px;
  height: auto;   
  padding: 0px 0px 0px 10px;
  visibility: ${({show}) => show ? 'visible': 'hidden'};
`;


const Voices = (
    {
      modal,
      apply,
      setModal,
      voices,
      setVoices,
    },
) => {
  const [males, setMales] = useState([]);
  const [females, setFemales] = useState([]);
  useEffect(() => {
    setMales(['Christopher', 'Guy', 'Jacob', 'Rick', 'Ryan Multilingual', 'Davis', 'Jack', 'Steffan', 'Angel', 'Arnold', 'Bart', 'Den', 'Oliver', 'Roger', 'Tony']);
    setFemales(['Sara', 'Aria', 'Ashley', 'Cora', 'Amber', 'Elizabeth', 'Ellis', 'Fenny', 'Hanna', 'Jennifer', 'Jenny', 'Karen', 'Kelsy', 'Mary', 'Melissa', 'Michelle']);
  }, []);
  const handlerCheckbox = (i) => {
    if (!voices.find((it) => it.value === i)) {
      setVoices([...voices, {
        title: i,
        value: i,
      }]);
    } else {
      setVoices(voices.filter((it) => it.value !== i));
    }
  };
  const handlerChange = (i, text) => {
    setVoices(voices.map((it) => {
      return (
        {
          ...it,
          title: i == it.value ? text : it.title,
        }
      );
    }));
  };
  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      title="Voiceover voices"
      primaryAction={{
        content: 'Apply',
        onAction: () => apply(),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setModal(false),
        },
      ]}
    >
      <Modal.Section>
        <Card>
          <Content>
            <Wrapper>
              <BlockStack gap="500">
                {males.map((i, index) => (
                  <Row key={index}>
                    <Checkbox
                      label={i}
                      checked={voices.find((it) => it.value === i)}
                      onChange={() => handlerCheckbox(i)}
                    />
                    <WrapperText show={voices.find((it) => it.value === i)}>
                      <TextField
                        value={voices.find((it) => it.value === i)?.title}
                        onChange={(e) => handlerChange(i, e)}
                        autoComplete="off"
                      />
                    </WrapperText>
                  </Row>
                ))}
              </BlockStack>
            </Wrapper>
            <BlockStack gap="500">
              {females.map((i, index) => (
                <Row key={index}>
                  <Checkbox
                    label={i}
                    checked={voices.find((it) => it.value === i)}
                    onChange={() => handlerCheckbox(i)}
                  />
                  <WrapperText show={voices.find((it) => it.value === i)}>
                    <TextField
                      value={voices.find((it) => it.value === i)?.title}
                      onChange={(e) => handlerChange(i, e)}
                      autoComplete="off"
                    />
                  </WrapperText>
                </Row>
              ))}
            </BlockStack>
          </Content>
        </Card>
      </Modal.Section>
    </Modal>
  );
};

export default Voices;
