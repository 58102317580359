import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Card, TextField, BlockStack, Checkbox} from '@shopify/polaris';
import axios from 'axios';

const clearArray = (array) => {
  while (array.length > 0) {
    array.pop();
  }
};

const formatted = (value) => {
  const arr = [];
  const storage = [];
  const processedLesson = value.replace(/\./g, '.*').replace(/\!/g, '!*').replace(/\?/g, '?*');
  const regex = /^\w+\s*:\s/;
  processedLesson.split('*').forEach((item) => {
    const sentence = item.trim();
    if (sentence !== '') {
      if (storage.length === 0 || !regex.test(sentence)) {
        storage.push(sentence);
      } else if (regex.test(sentence)) {
        arr.push([
          ...storage,
        ]);
        clearArray(storage);
        storage.push(sentence);
      }
    }
  });
  arr.push(storage);
  return arr;
};


const DialogSentences = (
    {
      modal,
      setModal,
      setModalVoices,
      setDialogSentences,
    },
) => {
  const [value, setValue] = useState('');
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      title="Dialog Sentences"
      primaryAction={{
        content: 'Apply',
        onAction: () => {
          const data = formatted(value);
          setDialogSentences(data);
          setModal(false);
          setModalVoices(true);
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setModal(false);
            setModalVoices(true);
          },
        },
      ]}
    >
      <Modal.Section>
        <Card>
          <TextField
            label="Full text"
            value={value}
            multiline={20}
            onChange={handleChange}
            autoComplete="off"
          />
        </Card>
      </Modal.Section>
    </Modal>
  );
};

export default DialogSentences;
