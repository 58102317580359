import React, {useState, Fragment} from 'react';
import '@shopify/polaris/build/esm/styles.css';
import Auth from './auth/Auth';
import Navigation from './navigation/Navigation';


const App = () => {
  const [token, setToken] = useState('');
  return (
    <Fragment>
      {token ? (
        <Navigation />
      ) : (
        <Auth setToken={setToken} />
      )}
    </Fragment>
  );
};

export default App;
