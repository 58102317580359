import React, {useEffect, useState, Fragment} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AppProvider, Button, Modal, Page, Spinner, Text} from '@shopify/polaris';
import styled from 'styled-components';
import axios from 'axios';
import PopUpAudioView from '../createLesson/lesson/popUpAudio/PopUpAudioView';
import {nanoid} from 'nanoid';
import {renderSentence} from '../createLesson/lesson/renderLesson/render';

const Plug = styled.div`
  width: 100vw;
  height: 100vh;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WrapperLesson = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap; 
`;

const Audio = styled.audio`
  width: 100%;
`;

const Source = styled.source`
  width: 100%;
`;

const Footer = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px;
`;

const WrapperSpinner = styled.div`
  width: 100%;
  height: 100%;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const clearArray = (array) => {
  while (array.length > 0) {
    array.pop();
  }
};

const getAudioUrl = (filename) => {
  return `${process.env.REACT_APP_AUDIO_FILE_URL}/${filename}`;
};

const Lesson = () => {
  const {id} = useParams();
  const [modal, setModal] = useState(false);
  const [spinnerDelete, setSpinnerDelete] = useState(false);
  const [error, setError] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [lesson, setLesson] = useState(null);
  const [content, setContent] = useState(null);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const get = async () => {
      try {
        setSpinner(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/getLesson`, {
          id,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setLesson(res.data.lesson);
        setSpinner(false);
      } catch (e) {
        setSpinner(false);
        console.log(e);
      }
    };
    get().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (lesson) {
      const arr = [];
      const storage = [];
      let content = [];
      lesson.content.split('*').forEach((item) => {
        const sentence = item.trim();
        if (storage.length === 0 || (item !== '-' && item[0] !== '-')) {
          storage.push(sentence);
        } else if (item === '-' || item[0] === '-') {
          arr.push([
            ...storage,
          ]);
          clearArray(storage);
          storage.push(sentence);
        }
      });
      arr.push(storage);
      arr.forEach((item) => {
        content = content.concat(
            [<div key={nanoid()} style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>
              {item.map((i) => {
                const sentence = i.trim();
                if (sentence !== '') {
                  return renderSentence(
                      {
                        sentence,
                        setOpen,
                      },
                  );
                }
              })}
            </div>],
        );
      });
      setContent(content);
    }
  }, [lesson]);

  const handler = () => {
    const deleter = async () => {
      try {
        setSpinnerDelete(true);
        await axios.delete(`${process.env.REACT_APP_API_URL}/deleteLesson/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setSpinnerDelete(false);
        setModal(false);
        navigate('/');
      } catch (e) {
        setSpinnerDelete(false);
        setError(e.message);
        console.log(e);
      }
    };
    deleter().catch((e) => console.error(e));
  };
  return (
    <AppProvider i18n='en'>
      <Page
        title={lesson?.title}
      >
        {!spinner ? (
          <Fragment>
            <WrapperLesson>
              {content}
            </WrapperLesson>
            <Audio controls>
              <Source src={`${getAudioUrl(lesson?.fileName)}`} type="audio/mpeg" />
            </Audio>
            {open ? (
              <PopUpAudioView
                lessonId={lesson?._id}
                sentence={open}
                setOpen={setOpen}
              />
            ) : null}
          </Fragment>
        ) : (
          <Plug>
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </Plug>
        )}
        <Footer>
          <Button onClick={() => setModal(true)} variant="plain" tone="critical">
            Delete
          </Button>
        </Footer>
      </Page>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        title="Deleting an sentence"
        primaryAction={{
          content: 'Cancel',
          onAction: () => setModal(false),
        }}
        secondaryActions={[
          {
            content: 'Delete',
            onAction: () => handler(),
          },
        ]}
      >
        <Modal.Section>
          {spinnerDelete ? (
          <WrapperSpinner>
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </WrapperSpinner>
          ) : (
          <Fragment>
            {error ? (
            <Text as={false}>
              <b>{error}</b>
            </Text>
            ) : (
            <Text as={false}>
              Do you really want to delete the lesson?
            </Text>
            )}
          </Fragment>
          )}
        </Modal.Section>
      </Modal>
    </AppProvider>
  );
};

export default Lesson;


