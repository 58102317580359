import React from 'react';
import styled from 'styled-components';
import {Text} from '@shopify/polaris';
import {ThreeDots} from 'react-loader-spinner';
import Triangle from './triangle/Triangle';

const Container = styled.div`
  left: 0px;
  bottom: 100%;
  padding: 5px;
  visibility: ${({open}) => open ? 'visible' : 'hidden'};
  border-radius: 8px;
  position: absolute;
  background: #32B89E;
`;

const WrapperLoader = styled.div`
  width: 40px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
`;


const PopUp = ({open, loader, text}) => {
  return (
    <Container open={open}>
      {loader && !text ? (
        <WrapperLoader>
          <ThreeDots
            height="30"
            width="30"
            radius="9"
            color="white"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </WrapperLoader>
      ) : (
        <Text as={false}>
          {text}
        </Text>
      )}
      <Triangle/>
    </Container>
  );
};

export default PopUp;
