import React, {useEffect, useState} from 'react';
import {nanoid} from 'nanoid';
import styled from 'styled-components';
import {TextField, Card, Button, Select, ButtonGroup} from '@shopify/polaris';
import axios from 'axios';


const Container = styled.div`
  margin: 0px 0px 20px 0px;
  border: ${({job}) => job ? '1px solid red' : 'none'};
`;

const WrapperFooter = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const WrapperButton = styled.div`
  height: 100%;   
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled.div`
  width: 20px;
  height: 100%;
`;


const formatted = (value) => {
  const formattedLesson = [];
  const processedLesson = value.replace(/\./g, '.*').replace(/\!/g, '!*').replace(/\?/g, '?*');
  processedLesson.split('*').forEach((item, index) => {
    const sentence = item.trim();
    if (sentence !== '') {
      const str = sentence.replace(/^.+:/, '-').replace(/^.-:/, '-');
      formattedLesson.push(str);
    }
  });
  return formattedLesson;
};


const TextHandler = (
    {
      lessonId,
      voices,
      selected,
      setSelected,
      dialogSentences,
      sentences,
      setSaver,
      setSynchronizer,
      setSentences,
    },
) => {
  const [value, setValue] = useState('');
  const [number, setNumber] = useState(0);
  const [job, setJob] = useState(false);
  const [buttons, setButtons] = useState('break');
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handlerBreak = () => {
    setButtons('saver');
    const sentencesAdd = formatted(value).map((s) => (
      {
        temporaryId: nanoid(),
        sentence: s,
        lessonId,
      }
    ));
    setSentences([...sentencesAdd.reverse(), ...sentences]);
    localStorage.setItem('sentences', JSON.stringify([...sentencesAdd, ...sentences]));
  };

  const next = () => {
    if (number < dialogSentences.length - 1) {
      setNumber(number + 1);
    }
  };

  const prev = () => {
    if (number > 0) {
      setNumber(number - 1);
    } else {
      setNumber(dialogSentences.length - 1);
    }
  };

  useEffect(() => {
    if (dialogSentences.length) {
      setValue(dialogSentences[number].join(''));
    }
  }, [number]);

  useEffect(() => {
    setSaver(['init']);
    setSynchronizer['init'];
    setJob(false);
    setSelected(null);
    setButtons('break');
  }, [value]);

  return (
    <Container job={job}>
      <Card>
        <TextField
          label="Enter"
          value={value}
          multiline={4}
          onChange={handleChange}
          autoComplete="off"
        />
        <WrapperFooter>
          <ButtonGroup variant="segmented">
            {voices.map((i, index) => (
              <Button
                key={index}
                pressed={i.value === selected}
                onClick={() => {
                  setSelected(i.value);
                }}
              >
                {i.title}
              </Button>
            ))}
          </ButtonGroup>
          <WrapperButton>
            <Button variant="tertiary" onClick={prev}>
              Prev
            </Button>
            <Spacer />
            {number < dialogSentences.length - 1 ? (
              <Button variant="tertiary" onClick={next}>
                Next
              </Button>
            ) : null}
          </WrapperButton>
          {buttons === 'break' ? (
            <WrapperButton>
              <Button
                variant="primary"
                disabled={!value.length || selected === null}
                onClick={handlerBreak}
              >
                Break
              </Button>
            </WrapperButton>
          ) : null}
          <WrapperButton>
            {buttons === 'saver' ? (
              <Button tone='success' variant="plain" onClick={() => {
                setButtons('sync');
                setSaver(['save']);
              }}>
                Saver
              </Button>
            ) : null}
            <Spacer />
            {buttons === 'sync' ? (
              <Button variant="tertiary" onClick={() => {
                setJob(true);
                setSynchronizer(['sync']);
              }}>
                Synchronizer
              </Button>
            ) : null}
          </WrapperButton>
        </WrapperFooter>
      </Card>
    </Container>
  );
};

export default TextHandler;
