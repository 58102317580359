import axios from 'axios';
const getAudioUrl = (filename) => {
  return `${process.env.REACT_APP_AUDIO_FILE_URL}/${filename}`;
};

export default getAudioUrl;

export const getWord = async ({title, lessonId}) => {
  try {
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getWord`,
        {
          title,
          lessonId,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
    );
    return {
      ...response.data.word,
      audio: getAudioUrl(response.data.word?.fileName),
    };
  } catch (e) {
    throw String(e);
  }
};
