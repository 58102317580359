import {useNavigate} from 'react-router-dom';
import {
  AppProvider,
  Frame,
  Navigation,
} from '@shopify/polaris';
import {
  HomeIcon,
  PageAddIcon,
  ContractFilledIcon,
} from '@shopify/polaris-icons';
import React from 'react';

const NavigationMenu = ({children}) => {
  const navigate = useNavigate();
  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        separator
        title="Main"
        items={[
          {
            label: 'Home',
            icon: HomeIcon,
            onClick: () => navigate('/'),
            selected: location.pathname === '/',
          },
          {
            label: 'Sentence',
            icon: PageAddIcon,
            selected: location.pathname === '/sentence',
            onClick: () => navigate('/sentence'),
          },
          {
            label: 'Lesson',
            icon: ContractFilledIcon,
            selected: location.pathname === '/createLesson',
            onClick: () => navigate('/createLesson'),
          },
        ]}
      />
    </Navigation>
  );
  return (
    <AppProvider i18n='en'>
      <Frame
        navigation={navigationMarkup}
      >
        {children}
      </Frame>
    </AppProvider>
  );
};

export default NavigationMenu;
