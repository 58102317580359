import React, {Fragment, useEffect, useState} from 'react';
import {Card as CardPolaris, Button, TextField, Spinner} from '@shopify/polaris';
import styled from 'styled-components';
import axios from 'axios';

const Footer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const WrapperSend = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Spacer = styled.div`
  width: 20px;
  height: 100%;
`;

const WrapperSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: ${({$spinner}) => $spinner ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000000000000000000000000;
  justify-content: center;
  align-items: center;
  background: darkseagreen;
  opacity: 0.8;
`;

const WrapperErrorApi = styled.div`
  width: auto;
  height: 100%;   
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorApi = styled.p`
  color: red;
  padding: 0px 0px 5px 0px;
`;

const isLatinAlphabet = (str) => {
  let result = null;
  if (!( /^[a-zA-Z0-9,.!? '-]+$/.test(str) && !/\s{2,}/.test(str) )) {
    result = 'invalid characters';
  }
  return result;
};

const isRightDialogue = (str) => {
  let result = null;
  if (str[0] === '-' && str[1] !== ' ') {
    result = 'invalid characters';
  }
  return result;
};

const validate = (str) => {
  let result = 'invalid characters';
  result = isLatinAlphabet(str);
  if (result === null) {
    result = isRightDialogue(str);
  }
  return result;
};

const replaceObjectByTemporaryId = (array, temporaryId, newObject) => {
  console.log('1', array, temporaryId);
  const index = array.findIndex((obj) => obj.temporaryId === temporaryId);

  if (index !== -1) {
    array[index] = newObject;
  }

  return [...array];
};


const Add = (
    {
      index,
      temporaryId,
      setSaver,
      saver,
      synchronizer,
      setSynchronizer,
      sentence,
      voice,
      lessonId,
      setSentences,
      sentences,
    },
) => {
  const [value, setValue] = useState(sentence);
  const [error, setError] = useState(null);
  const [errorApi, setErrorApi] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [synch, setSynch] = useState(false);
  const [res, setRes] = useState(false);
  const [validation, setValidation] = useState(true);
  const handleChange = (newValue) => {
    setValue(newValue);
    if (validation) {
      setError(validate(newValue));
    }
  };

  const cancelValidation = () => {
    setValidation(false);
    setError(null);
  };
  const enableValidation = () => {
    setValidation(true);
    setError(validate(value));
  };
  const handler = () => {
    const create = async () => {
      try {
        setSpinner(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/createSentence`, {
          sentence: value,
          lessonId,
          voice,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setRes(res);
        setSpinner(false);
        setSynch(true);
      } catch (e) {
        setSpinner(false);
        setErrorApi(e.response.data.message);
        console.log(e);
      }
    };
    create().catch((e) => console.error(e));
  };

  const handlerDelete = () => {
    setSentences([...sentences.filter((i) => i.temporaryId !== temporaryId)]);
    localStorage.setItem('sentences', JSON.stringify([...sentences.filter((i) => i.temporaryId !== temporaryId)]));
  };

  const sync = () => {
    setSentences([...replaceObjectByTemporaryId(sentences, temporaryId, res.data)]);
    localStorage.setItem('sentences', JSON.stringify([...replaceObjectByTemporaryId(sentences, temporaryId, res.data)]));
  };

  useEffect(() => {
    setError(validate(sentence));
  }, []);

  useEffect(() => {
    if (saver[0] === 'save') {
      handler();
      setSaver(['init']);
    }
  }, [saver]);

  useEffect(() => {
    if (synchronizer[0] === 'sync' && synch) {
      const delay = 0 ? 0 : Number(`${index}00`);
      setTimeout(() => {
        sync();
        setSynchronizer(['init']);
      }, delay);
    }
  }, [synchronizer]);


  return (
    <Fragment>
      <CardPolaris>
        <TextField
          label="Sentence"
          value={value}
          multiline={4}
          error={error}
          onChange={handleChange}
          autoComplete="off"
        />
        <Footer>
          {synch ? (
            <Button onClick={sync} variant="primary">
              Sync
            </Button>
          ) : (
            <Fragment>
              <WrapperSend>
                <Button onClick={handlerDelete} variant="primary">
                  Delete
                </Button>
                <Spacer />
              </WrapperSend>
              <WrapperSend>
                <Fragment>
                  {validation ? (
                    <Button onClick={cancelValidation} variant="plain" tone="critical">
                      Cancel validation
                    </Button>
                  ) : (
                    <Button onClick={enableValidation} variant="plain" tone="success">
                      Enable validation
                    </Button>
                  )}
                </Fragment>
                <Spacer />
                <Button onClick={handler} variant="primary" disabled={!(value.length && !error)}>
                  Save sentence
                </Button>
              </WrapperSend>
            </Fragment>
          )}
        </Footer>
        <WrapperSpinner $spinner={spinner || errorApi}>
          {errorApi ? (
            <WrapperErrorApi>
              <ErrorApi>
                {errorApi}
              </ErrorApi>
              <Button onClick={() => setErrorApi(false)} variant="plain" tone="critical">
                Cancel
              </Button>
            </WrapperErrorApi>
          ) : (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          )}
        </WrapperSpinner>
      </CardPolaris>
    </Fragment>
  );
};

export default Add;
