import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import React from 'react';

const DragAndDrop = (
    {
      items,
      handlerSort,
      margin,
      widthList,
      borderRadius,
      background,
      isDragDisabled,
    },
) => {
  const getListStyle = () => ({
    background,
    width: widthList,
  });
  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: 'none',
      padding: 0,
      borderRadius: borderRadius || 0,
      margin,
      background,
      ...draggableStyle,
    };
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from([...list]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
    );

    handlerSort(newItems, newItems.find(
        (item) => item.id === `item-${result.source.index}`).data.sentenceId);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable isDragDisabled={isDragDisabled} key={item.id}
                draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDrop;
