import React, {Fragment, useEffect, useState} from 'react';
import {AppProvider, Button, Page, Spinner, TextField} from '@shopify/polaris';
import {renderSentence} from './lesson/renderLesson/render';
import PopUpAudioView from './lesson/popUpAudio/PopUpAudioView';
import {nanoid} from 'nanoid';
import styled from 'styled-components';
import Card from './card/Card';
import DragAndDrop from '../components/dragAndDrop/DragAndDrop';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const Content = styled.div`
  width: 100%;
  padding: 20px 0px 20px 0px;
`;

const WrapperLesson = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap; 
`;

const Spacer = styled.div`
  width: 100%;
  height: 20px;
`;

const Footer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const WrapperCreate = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Plug = styled.div`
  width: 100%;
  height: 100vh;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WrapperError = styled.div`
  width: auto;
  height: auto;   
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Error = styled.p`
  color: red;
  padding: 0px 0px 5px 0px;
`;

const clearArray = (array) => {
  while (array.length > 0) {
    array.pop();
  }
};


const CreateLesson = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(null);
  const [sentences, setSentences] = useState([]);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [lesson, setLesson] = useState(false);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem('sentences'))?.length) {
      setSentences([...JSON.parse(localStorage.getItem('sentences')).filter((i) => i.sentenceId).reverse()]);
    }
    if (localStorage.getItem('type')) {
      setType(localStorage.getItem('type'));
    }
  }, []);
  const handlerDragEnd = (value) => {
    setSentences(value.map((i) => i.data));
  };
  const goToCreateLesson = () => {
    const arr = [];
    const storage = [];
    let content = [];
    sentences.forEach((item) => {
      const sentence = item.sentence.trim();
      if (storage.length === 0 || (sentence !== '-' && sentence[0] !== '-')) {
        storage.push(sentence);
      } else if (sentence === '-' || sentence[0] === '-') {
        arr.push([
          ...storage,
        ]);
        clearArray(storage);
        storage.push(sentence);
      }
    });
    arr.push(storage);
    arr.forEach((item) => {
      content = content.concat(
          [<div key={nanoid()} style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>
            {item.map((i) => {
              const sentence = i.trim();
              if (sentence !== '') {
                return renderSentence(
                    {
                      sentence,
                      setOpen,
                    },
                );
              }
            })}
          </div>],
      );
    });
    setLesson(content);
  };
  const goToSentence = () => {
    navigate('/sentence');
  };
  const content = sentences.map((item, index) => (
    {
      id: `item-${index}`,
      data: item,
      content: <Card sentence={item.sentence} />,
    }
  ));

  const createLesson = () => {
    const create = async () => {
      try {
        setSpinner(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/createLesson`, {
          lesson: sentences,
          lessonId: localStorage.getItem('lessonId'),
          type,
          title,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setSpinner(false);
        localStorage.removeItem('lessonId');
        localStorage.removeItem('sentences');
        navigate('/');
      } catch (e) {
        setSpinner(false);
        setError(e.response.data.message);
        console.log(e);
      }
    };
    create().catch((e) => console.error(e));
  };


  return (
    <AppProvider i18n='en'>
      {sentences.length && !spinner && !error ? (
        <Fragment>
          <Page
            title={localStorage.getItem('lessonId')}
          >
            {!lesson ? (
              <Fragment>
                <TextField
                  autoComplete='off'
                  label="Title"
                  placeholder="Enter title"
                  value={title}
                  maxLength={300}
                  onChange={(v) => setTitle(v)}
                />
                <Spacer />
                <TextField
                  autoComplete='off'
                  label="Type"
                  placeholder="Enter type"
                  value={type}
                  maxLength={300}
                  onChange={(v) => {
                    setType(v);
                    localStorage.setItem('type', String(v));
                  }}
                />
                <Content>
                  <DragAndDrop
                    items={content}
                    isDragDisabled={false}
                    margin={20}
                    widthList="100%"
                    borderRadius="16px"
                    handlerSort={handlerDragEnd}
                  />
                </Content>
              </Fragment>
            ) : (
              <WrapperLesson>
                {lesson}
              </WrapperLesson>
            )}
            <Footer>
              {!lesson ? (
                <Button
                  size="large"
                  disabled={!title || !type}
                  onClick={goToCreateLesson}>
                  Go to creating a lesson
                </Button>
              ) : (
                <Fragment>
                  <WrapperCreate>
                    <Button
                      size="large"
                      onClick={() => setLesson(false)}>
                      Back
                    </Button>
                    <Button
                      size="large"
                      onClick={createLesson}>
                      Create lesson
                    </Button>
                  </WrapperCreate>
                </Fragment>
              )}
            </Footer>
          </Page>
          {open ? (
            <PopUpAudioView
              lessonId={localStorage.getItem('lessonId')}
              sentence={open}
              setOpen={setOpen}
            />
          ) : null}
        </Fragment>
      ) : (
        <Plug>
          {spinner ? (
            <Fragment>
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </Fragment>
          ) : (
            <Fragment>
              {error ? (
                <WrapperError>
                  <Error>
                    {error}
                  </Error>
                  <Button
                    size="large"
                    onClick={() => setError(null)}>
                    Back
                  </Button>
                </WrapperError>
              ) : (
                <Button
                  size="large"
                  onClick={goToSentence}>
                  Go to sentence
                </Button>
              )}
            </Fragment>
          )}
        </Plug>
      )}
    </AppProvider>
  );
};

export default CreateLesson;
