import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Card, Text, Button, Modal, Spinner} from '@shopify/polaris';
import {
  DeleteIcon,
  PlayIcon,
  TransactionIcon,
  SelectIcon,
  StopCircleIcon,
} from '@shopify/polaris-icons';
import axios from 'axios';

const Container = styled.div`
  width: 100%;
  height: 100%;   
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  width: 80%;
  height: 100%;
  padding: 0px 10px 0px 10px;
`;

const WrapperIcons = styled.div`
  width: 20%;
  height: 100%;   
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const WrapperSpinner = styled.div`
  width: 100%;
  height: 100%;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const Display = (
    {
      setSentences,
      sentences,
      title,
      sentenceId,
      translation,
      parse,
      fileName,
    },
) => {
  const [titleMarker, setTitleMarker] = useState('title');
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(false);
  const [modal, setModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);

  const handler = () => {
    const deleter = async () => {
      try {
        setSpinner(true);
        await axios.delete(`${process.env.REACT_APP_API_URL}/deleteSentence/${sentenceId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setSpinner(false);
        setModal(false);
        setSentences(sentences.filter((s) => s.sentenceId !== sentenceId));
        localStorage.setItem('sentences', JSON.stringify(sentences.filter((s) => s.sentenceId !== sentenceId)));
      } catch (e) {
        setSpinner(false);
        setError(e.message);
        console.log(e);
      }
    };
    deleter().catch((e) => console.error(e));
  };

  useEffect(() => {
    const pl = new Audio(`${process.env.REACT_APP_AUDIO_FILE_URL}/${fileName}`);
    pl.addEventListener('loadedmetadata', () => {
      setPlayer(pl);
    });
    pl.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  }, []);


  return (
    <Fragment>
      <Card>
        <Container>
          <Content>
            <Text as={false}>
              {titleMarker === 'title' ? (
                title
              ) : null}
              {titleMarker === 'parse' ? (
                parse
              ) : null}
              {titleMarker === 'translation' ? (
                translation
              ) : null}
            </Text>
          </Content>
          <WrapperIcons>
            <Button
              icon={TransactionIcon}
              variant={titleMarker === 'translation' ? 'primary' : 'secondary'}
              onClick={() => {
                if (titleMarker === 'translation') {
                  setTitleMarker('title');
                } else {
                  setTitleMarker('translation');
                }
              }}
            />
            <Button
              icon={SelectIcon}
              variant={titleMarker === 'parse' ? 'primary' : 'secondary'}
              onClick={() => {
                if (titleMarker === 'parse') {
                  setTitleMarker('title');
                } else {
                  setTitleMarker('parse');
                }
              }}
            />
            {player ? (
              <Button
                icon={isPlaying ? StopCircleIcon : PlayIcon}
                onClick={() => {
                  setIsPlaying(!isPlaying);
                  if (!isPlaying) {
                    player?.play();
                  } else {
                    player?.pause();
                  }
                }}
                variant={isPlaying ? 'primary' : 'secondary'}
              />
            ) : null}
            <Button
              icon={DeleteIcon}
              onClick={() => {
                setModal(!modal);
              }}
            />
          </WrapperIcons>
        </Container>
      </Card>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        title="Deleting an sentence"
        primaryAction={{
          content: 'Cancel',
          onAction: () => setModal(false),
        }}
        secondaryActions={[
          {
            content: 'Delete',
            onAction: () => handler(),
          },
        ]}
      >
        <Modal.Section>
          {spinner ? (
            <WrapperSpinner>
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </WrapperSpinner>
          ) : (
            <Fragment>
              {error ? (
                <Text as={false}>
                  <b>{error}</b>
                </Text>
              ) : (
                <Text as={false}>
                  Do you really want to delete the sentence? <b>{`"${title.slice(0, 20) + '...'}"`}</b>
                </Text>
              )}
            </Fragment>
          )}
        </Modal.Section>
      </Modal>
    </Fragment>
  );
};

export default Display;
