import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  position: absolute;
  bottom: -8px;
  left: 0;
`;

const Tri = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid lightseagreen;
`;


const Triangle = () => {
  return (
    <Container>
      <Tri/>
    </Container>
  );
};

export default Triangle;
