import React from 'react';
import styled from 'styled-components';
import {Card as CardShopify, Text, InlineGrid, BlockStack} from '@shopify/polaris';

const Card = ({title, type}) => {
  return (
    <CardShopify roundedAbove="sm">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
            {type}
          </Text>
        </InlineGrid>
        <Text as="p" variant="bodyMd">
          {title}
        </Text>
      </BlockStack>
    </CardShopify>
  );
};

export default Card;
