import {useEffect, useRef, useState} from 'react';
import {getWord} from '../api';


const PopUpAudioHook = ({sentence, setOpen, lessonId}) => {
  const [targetLang, setTargetLang] = useState(null);
  const [audio, setAudio] = useState(null);
  const [loader, setLoader] = useState(true);
  const useOutside = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          const audio = document.getElementsByTagName('audio');
          audio[0].pause();
          audio[0].currentTime = 0;
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutside(wrapperRef);
  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      const word = await getWord({title: `${sentence.trim()}`, lessonId: lessonId});
      if (word) {
        setTargetLang(word.ru);
        setAudio(new Array(word.audio));
        setLoader(false);
      }
    };
    if (sentence) {
      fetch().catch();
    }
  }, [sentence]);
  return {
    targetLang,
    audio,
    loader,
    wrapperRef,
  };
};

export default PopUpAudioHook;
