import React from 'react';
import styled from 'styled-components';
import {Text} from '@shopify/polaris';
import PopUp from './popUp/PopUp';
import WordHook from './WordHook';

const Paragraph = styled.div`
  padding: 2px;
  margin: 0px;
  position: relative;
  cursor: pointer;
  pointer-events: ${({open}) => open ? 'none' : 'auto'};
`;


const WordView = ({sourceLang = 'en', setOpen, sentence}) => {
  const {
    targetLang,
    tooltip,
    loader,
    handlerClick,
    handlerOpen,
  } = WordHook({sourceLang, setOpen, sentence});
  return (
    <Paragraph open={tooltip} onClick={setOpen ? handlerOpen : handlerClick}>
      <Text as={false}>
        {sourceLang}
      </Text>
      <PopUp open={tooltip} loader={loader} text={targetLang}/>
    </Paragraph>
  );
};

export default WordView;
