import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {AppProvider, Icon, Page, Select, Spinner, TextField, Pagination} from '@shopify/polaris';
import {SearchIcon} from '@shopify/polaris-icons';
import Card from './card/Card';
import axios from 'axios';
import {useNavigate} from "react-router-dom";


const WrapperCard = styled.div`
  margin: 0px 0px 20px 0px;
  cursor: pointer;
`;

const Plug = styled.div`
  width: 100%;
  height: 100vh;   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 30px 0px;
  height: auto;
`;

const Wrapper = styled.div`
  width: ${({width}) => width}%;
  height: 100%;
`;

const WrapperPagination = styled.div`
  padding: 20px 0px 20px 0px;
`;

const getValue = (val) => {
  return val === '' ? null : val;
};


const Home = () => {
  const [selected, setSelected] = useState('type');
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [count, setCount] = useState(0);
  const [lessons, setLessons] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [nPerPage, setNPerPage] = useState(20);
  const [title, setTitle] = useState(null);
  const [type, setType] = useState(null);
  const [content, setContent] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const count = async () => {
      try {
        setSpinner(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/getCountLessons`, {
          title,
          type,
          content,
          nPerPage,
          pageNumber,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setCount(res.data.count);
        setSpinner(false);
      } catch (e) {
        setSpinner(false);
        console.log(e);
      }
    };
    const get = async () => {
      try {
        setSpinner(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/getLessons`, {
          title,
          type,
          content,
          nPerPage,
          pageNumber,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        setLessons(res.data.lessons);
        setSpinner(false);
      } catch (e) {
        setSpinner(false);
        console.log(e);
      }
    };
    count().catch((e) => console.error(e));
    get().catch((e) => console.error(e));
  }, [title, type, content, pageNumber]);


  const handlerSearch = (value) => {
    setSearch(value);
    setPageNumber(1);
    if (selected === 'type') {
      setType(getValue(value));
    }
    if (selected === 'title') {
      setTitle(getValue(value));
    }
    if (selected === 'content') {
      setContent(getValue(value));
    }
  };

  const handleSelectChange = (value) => {
    setTitle(null);
    setType(null);
    setContent(null);
    setSearch('');
    setSelected(value);
  };


  return (
    <AppProvider i18n='en'>
      <Page
        title="Home"
      >
        <Fragment>
          <Header>
            <Wrapper width={70}>
              <TextField
                label="Search"
                placeholder="Search by lessons"
                prefix={<Icon source={SearchIcon} tone="base" />}
                value={search}
                onChange={handlerSearch}
                autoComplete="off"
              />
            </Wrapper>
            <Wrapper width={20}>
              <Select
                label="Why search"
                options={[
                  {
                    label: 'type',
                    value: 'type',
                  },
                  {
                    label: 'title',
                    value: 'title',
                  },
                  {
                    label: 'content',
                    value: 'content',
                  },
                ]}
                onChange={handleSelectChange}
                value={selected}
              />
            </Wrapper>
          </Header>
          {!spinner ? (
            <Fragment>
              {lessons.map((l, index) => (
                <WrapperCard
                  key={index}
                  onClick={() => navigate(`/lesson/${l._id}`)}
                >
                  <Card
                    title={l.title}
                    type={l.type}
                  />
                </WrapperCard>
              ))}
              <WrapperPagination>
                <Pagination
                  onPrevious={() => {
                    setPageNumber(pageNumber - 1);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // Добавляет плавный скроллинг
                    });
                  }}
                  onNext={() => {
                    setPageNumber(pageNumber + 1);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // Добавляет плавный скроллинг
                    });
                  }}
                  type="table"
                  hasNext={!(lessons.length < nPerPage || (lessons.length * pageNumber) === count)}
                  hasPrevious={pageNumber > 1}
                  label={`${pageNumber}-${nPerPage} of ${count} lessons`}
                />
              </WrapperPagination>
            </Fragment>
          ) : (
            <Plug>
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </Plug>
          )}
        </Fragment>
      </Page>
    </AppProvider>
  );
};

export default Home;
