import React from 'react';
import {Card as CardPolaris, Text} from '@shopify/polaris';
import styled from 'styled-components';

const Container = styled.div``;


const Card = ({sentence}) => {
  return (
    <Container>
      <CardPolaris>
        <Text as={false}>
          {sentence}
        </Text>
      </CardPolaris>
    </Container>
  );
};

export default Card;
