import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';
import NavigationMenu from '../menuNavigation/MenuNavigation';
import Sentence from '../sentence/Sentence';
import CreateLesson from '../createLesson/CreateLesson';
import Home from '../home/Home';
import Lesson from '../lesson/Lesson';

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={(
          <NavigationMenu>
            <Home />
          </NavigationMenu>
        )} />
        <Route path="/sentence" element={(
          <NavigationMenu>
            <Sentence />
          </NavigationMenu>
        )} />
        <Route path="/createLesson" element={(
          <NavigationMenu>
            <CreateLesson />
          </NavigationMenu>
        )} />
        <Route path="lesson/:id" element={(
          <NavigationMenu>
            <Lesson />
          </NavigationMenu>
        )} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
