import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Modal, Spinner, Text} from '@shopify/polaris';
import PopUpAudioHook from './PopUpAudioHook';
import {renderSentence} from '../renderLesson/render';

const Content = styled.div`
  width: 100%;
  background: white;
  height: auto;
`;

const WrapperLoader = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: ${({$padding}) => $padding};
`;

const Source = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Section = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
`;

const Audio = styled.audio`
  width: 100%;
`;


const PopUpAudioView = (
    {
      lessonId,
      sentence,
      setOpen,
    },
) => {
  const {
    targetLang,
    audio,
    loader,
    wrapperRef,
  } = PopUpAudioHook({setOpen, sentence, lessonId});
  return (
    <Fragment>
      <Modal
        open={Boolean(sentence)}
        onClose={() => setOpen(false)}
        title="Listening to the sentence"
      >
        <Modal.Section>
          <Content ref={wrapperRef}>
            {loader ? (
              <WrapperLoader>
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </WrapperLoader>
            ) : (
              <Section>
                <Source>
                  {
                    renderSentence(
                        {
                          sentence,
                          setOpen: false,
                        },
                    )
                  }
                </Source>
                <Wrapper $padding="20px">
                  <Text as={false}>
                    {targetLang}
                  </Text>
                </Wrapper>
                <Wrapper $padding="20px">
                  <Audio
                    id="audio"
                    controls
                    src={audio}>
                  </Audio>
                </Wrapper>
              </Section>
            )}
          </Content>
        </Modal.Section>
      </Modal>
    </Fragment>
  );
};

export default PopUpAudioView;
