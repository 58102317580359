import React, {Fragment} from 'react';
import styled from 'styled-components';
import Add from './add/Add';
import Display from './display/Display';

const Wrapper = styled.div`
  margin: 0px 0px 20px 0px;
`;


const Card = (
    {
      saver,
      setSynchronizer,
      setSaver,
      synchronizer,
      sentences,
      setSentences,
      voice,
    },
) => {
  console.log('here', sentences);
  return (
    <Fragment>
      {sentences.map((s, index) => {
        return (
          <Wrapper key={s.temporaryId ? s.temporaryId : s.sentenceId}>
            {s.temporaryId ? (
              <Add
                key={s.sentenceId}
                temporaryId={s.temporaryId}
                sentence={s.sentence}
                lessonId={s.lessonId}
                saver={saver}
                synchronizer={synchronizer}
                setSynchronizer={setSynchronizer}
                setSaver={setSaver}
                voice={voice}
                sentences={sentences}
                setSentences={setSentences}
                index={index}
              />
            ) : (
              <Display
                key={s.sentenceId}
                sentenceId={s.sentenceId}
                title={s.sentence}
                parse={s.parseRu}
                translation={s.ru}
                lessonId={s.lessonId}
                fileName={s.fileName}
                sentences={sentences}
                setSentences={setSentences}
              />
            )}
          </Wrapper>
        );
      })}
    </Fragment>
  );
};

export default Card;


/**/


/* <Add
        lessonId={lessonId}
        sentences={sentences}
        setSentences={setSentences}
        sentence='I went to eat at the store'
        voice='Arnold'
      />*/


/* <Display />*/
