import {useState} from 'react';
import {getWord} from '../api';

const handlerWord = ({word}) => {
  let result = word.trim().toLowerCase();

  if (result[result.length - 1]?.match(/[^A-Za-z]+/g)) {
    result = result.slice(0, result.length - 1);
  }

  if (result[0]?.match(/[^A-Za-z]+/g)) {
    result = result.slice(1, result.length);
  }
  return result;
};


const WordHook = (
    {
      sourceLang,
      setOpen,
      sentence,
    },
) => {
  const [targetLang, setTargetLang] = useState(null);
  const [audio, setAudio] = useState(null);
  const [tooltip, setTooltip] = useState(false);
  const [loader, setLoader] = useState(false);
  const handlerClick = async () => {
    setTooltip(!tooltip);
    if (!targetLang && !audio) {
      setLoader(true);
      const word = await getWord({title: handlerWord({word: sourceLang})});
      if (word?.audio) {
        setTargetLang(word.ru);
        const playAudio = async () => {
          const player = new Audio(word.audio);
          await player.play();
          setAudio(new Array(word.audio));
          setTimeout(() => {
            setTooltip(false);
          }, 2000);
        };
        playAudio().catch(console.error);
      } else {
        setTooltip(false);
      }
      setLoader(false);
    } else {
      const playAudio = async () => {
        const player = new Audio(audio);
        await player.play();
        setAudio(new Array(audio));
        setTimeout(() => {
          setTooltip(false);
        }, 2000);
      };
      playAudio().catch(console.error);
    }
  };
  const handlerOpen = async () => {
    setOpen(sentence);
  };
  return {
    targetLang,
    tooltip,
    loader,
    handlerOpen,
    handlerClick,
  };
};

export default WordHook;
